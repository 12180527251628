import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { fontSize } from '@styles/globalStyles';
import { StylesConfig } from 'react-select';

export const standaloneDropdownContainer = (theme) => css`
  background-color: #fff;
  padding-bottom: 48px;
  flex: 1;
  margin-right: 16px;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  z-index: 998;
  height: 100px;
  box-shadow: ${theme.shadow.medium};

  .preloader {
    bottom: unset;
    top: 50px;
    right: 16px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    &:focus-within {
      box-shadow: 20px 20px 1000vh 1000vh rgba(0, 0, 0, 0.2);
      z-index: 999999;
    }
  }
`;

export const StyledSelect = styled.div`
  ${({ theme }) => standaloneDropdownContainer(theme)}

  &:first-of-type {
    z-index: 999;
  }
  &:nth-of-type(2) {
    z-index: 998;
  }
  &:nth-of-type(3) {
    z-index: 997;
  }
  &:last-of-type {
    z-index: 996;
  }

  *:focus {
    outline: none;
  }

  @media only screen and (max-width: 1024px) {
    width: calc(50% - 16px);
    flex: unset;
    margin-right: 0;
    flex-wrap: wrap;

    &:focus-within {
      box-shadow: 20px 20px 1000vh 1000vh rgba(0, 0, 0, 0.2);
      z-index: 999999;
    }
  }

  @media only screen and (max-width: 769px) {
    width: 100%;
    border-top: 1px solid #ccecf9;
    box-shadow: none;

    &:first-of-type {
      border-top: none;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const Label = styled.label`
  position: absolute;
  top: 13px;
  left: 24px;
  font-size: ${fontSize(14)};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: -0.21px;
  color: #002330;
`;

export const customStyles: StylesConfig = {
  option: (provided) => ({
    ...provided,
    padding: 20,
    width: '100%',
    fontSize: 16,
    letterSpacing: '-0.27px',
    color: '#00405b',
    cursor: 'pointer',
  }),
  container: () => ({
    width: '50%',
    '@media only screen and (max-width: 769px)': {
      width: '100%',
    },
  }),
  menu: () => ({ width: 0 }),
  menuList: (provided) => ({
    ...provided,
    position: 'absolute',
    width: '100%',
    top: 103,
    backgroundColor: '#fff',
    boxShadow: '4px 8px 20px 0 rgba(0, 64, 91, 0.08)',
    borderRadius: 5,
  }),
  control: () => ({
    width: '100%',
    height: 100,
    paddingTop: 50,
    position: 'absolute',
    top: 0,
    paddingLeft: 16,
  }),
  dropdownIndicator: () => ({
    display: 'none',
    '@media only screen and (max-width: 769px)': {
      display: 'block',
      position: 'absolute',
      right: 20,
      top: 20,
    },
  }),
};
